import React from 'react';

const Links = ({ children }) => {
  return (
    <div className="links">
      {children}
    </div>
  );
};

Links.Link = ({ href, title, text, isExternal = false }) => {
  const additionalProps = isExternal ? { target: "_blank", rel: "noreferrer" } : {};

  return (
    <a href={href} className="link" {...additionalProps}>
      <h2>{title} &rarr;</h2>
      <p>{text}</p>
    </a>
  );
};

export default Links;
