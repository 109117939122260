import * as React from 'react';
import Layout from '../components/Layout.js';
import Links from '../components/links/Links.js';

const IndexPage = () => {
  return (
    <Layout title={{ black: 'Welcome To', blue: 'The Dugout' }}>
      <Links>
        <Links.Link
          href="/banners"
          title="Banners"
          text="Configure advertising banners for competitions, teams and events."
        />
        <Links.Link
          href="https://graphs.mysportsdiary.net"
          title="Grafana"
          text="Open the My Sports Diary Grafana monitoring dashboard."
          isExternal={true}
        />
      </Links>
    </Layout>
  );
};

export default IndexPage;
